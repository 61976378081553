import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from '@solid-ui-layout/Layout';
import Seo from '@solid-ui-components/Seo';
import Divider from '@solid-ui-components/Divider';
import GoogleMapReact from 'google-map-react';
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01';
import ModalSimple from '@solid-ui-blocks/Modal/Block02';
import Header from '@solid-ui-blocks/Header/Block01';
import Blog from '@solid-ui-blocks/Blog/Block01';
import Footer from '@solid-ui-blocks/Footer/Block01';
import { normalizeBlockContentNodes } from '@blocks-helpers';
import theme from './_theme';
import { FaPhoneAlt, FaMapMarkerAlt } from 'react-icons/fa';

const AnyReactComponent = ({ text }) => (
  <div style={styles.marker}>
    {text}
  </div>
);

const IndexPage = props => {
  const { allBlockContent } = props.data;
  const content = normalizeBlockContentNodes(allBlockContent?.nodes);
  const [contact, setContact] = useState({
    name: '',
    email: '',
    message: '',
  });

  const addData = object => {
    var axios = require('axios');
    var data = new FormData();
    var string = JSON.stringify(object);
    data.append('from', 'Triaxo Solutions <postmaster@sandboxeda9308605114da0af134936e11b1524.mailgun.org>');
    data.append('to', 'shehryarkn@gmail.com');
    data.append('subject', 'Triaxo Solutions <triaxo.com>');
    data.append('text', string);
    data.append('domain', 'sandboxeda9308605114da0af134936e11b1524.mailgun.org');

    var config = {
      method: 'post',
      url: 'https://api.mailgun.net/v3/sandboxeda9308605114da0af134936e11b1524.mailgun.org/messages',
      headers: {
        Authorization: 'Basic YXBpOjk5NTA0MzBhZDE3ZDNlZTIxZDdhZjFmNzFkOTFlMmEzLTFiOGNlZDUzLTMzZGFjM2E1',
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const addForm = async () => {
    try {
      var response = await addData(contact);
      console.log('add', response);
      alert('Message Sent Successfully');
    } catch (err) {
      console.log('error');
    }
  };

  const [screenWidth, setScreenWidth] = useState();
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Layout theme={theme} {...props}>
      <Seo title="Home" />
      <ModalWithTabs content={content['authentication']} reverse />
      <ModalWithTabs content={content['contact']} />
      <ModalSimple content={content['advertisement']} />
      <Header content={content['header']} />
      <Divider space="4" />
      <Divider space="4" />
      <Divider space="4" />
      <Divider space="4" />

      <Blog content={content['latest-blogs']} />

      <div style={styles.container}>
        <form style={styles.form} className="fadeIn">
          <input
            style={styles.input}
            type="text"
            name="name"
            onChange={e => setContact({ ...contact, name: e.target.value })}
            placeholder="Full Name"
          />
          <input
            style={styles.input}
            type="email"
            name="email"
            onChange={e => setContact({ ...contact, email: e.target.value })}
            placeholder="Email Address"
          />
          <textarea
            style={styles.input}
            name="message"
            onChange={e => setContact({ ...contact, message: e.target.value })}
            placeholder="Enter Your Message"
          ></textarea>
          <button style={styles.button} type="button" onClick={addForm}>
            Send Message
          </button>
        </form>
        <div style={styles.mapContainer} className="fadeIn">
          <GoogleMapReact
            bootstrapURLKeys={{ key: '' }}
            defaultCenter={{
              lat: 30.341790357021434,
              lng: -97.75501897795894,
            }}
            defaultZoom={11}
          >
            <AnyReactComponent lat={30.341790357021434} lng={-97.75501897795894} text="Office Location" />
          </GoogleMapReact>
        </div>
      </div>

      <Divider space="4" />
      <Blog content={content['heading']} />
      <div style={styles.container} className="fadeIn">
        <div style={styles.infoBox}>
          <h3>Head Office :</h3>
          <p style={styles.contactInfo}>
            <FaPhoneAlt style={{ marginRight: '8px' }} />
            <span style={styles.phone} onClick={() => window.open('tel:+15122560433')}>
              +1 (512) 256-0433
            </span>
            <br />
            <FaMapMarkerAlt style={{ marginRight: '8px' }} />
            <span style={styles.address}>
              5900 Balcones Drive, STE 100
              <br />
              Austin, TX 78731
            </span>
          </p>
        </div>

        <div style={{ ...styles.infoBox, ...styles.infoBoxAlt }}>
          <h3>Pakistan Office:</h3>
          <p style={styles.contactInfo}>
            <FaPhoneAlt style={{ marginRight: '8px' }} />
            <span style={styles.phone} onClick={() => window.open('tel:+924232212700')}>
              (042) 35948673
            </span>
            <br />
            <FaMapMarkerAlt style={{ marginRight: '8px' }} />
            <span style={styles.address}>
              49 Aibak Block, Garden Town,
              <br />
              Lahore
            </span>
          </p>
        </div>
      </div>


      <Divider space="5" />
      <Footer content={content['footer']} />
    </Layout>
  );
};

const styles = {
  marker: {
    color: '#fff',
    background: '#38bdf8',
    padding: '8px 12px',
    borderRadius: '5px',
    transition: 'transform 0.3s ease',
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '20px',
    margin: '30px',
    animation: 'fadeIn 0.8s ease-in-out',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    animation: 'fadeIn 1s ease-in-out',
  },
  input: {
    width: '100%',
    padding: '0.75rem 1rem',
    marginBottom: '15px',
    fontSize: '1rem',
    borderRadius: '5px',
    border: '1px solid #cbd5e0',
    backgroundColor: '#f7fafc',
    color: '#2d3748',
    transition: 'border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  },
  inputFocus: {
    borderColor: '#38bdf8',
    boxShadow: '0 0 5px rgba(56, 189, 248, 0.5)',
  },
  button: {
    padding: '15px 30px',
    backgroundColor: '#38bdf8',
    color: '#fff',
    borderRadius: '50px',
    border: 'none',
    fontSize: '1rem',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease, transform 0.2s ease',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)',
    width: "200px"
  },
  buttonHover: {
    backgroundColor: '#0ea5e9',
    transform: 'translateY(-2px)',
  },
  mapContainer: {
    height: '100%',
    width: '100%',
    borderRadius: '15px',
    overflow: 'hidden',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
  },
  infoBox: {
    backgroundColor: '#718096',
    textAlign: 'center',
    borderRadius: '15px',
    padding: '30px',
    color: '#fff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease',
  },
  infoBoxAlt: {
    backgroundColor: '#38bdf8',
  },
  contactInfo: {
    lineHeight: '1.5',
    fontSize: '1.2rem',
  },
  phone: {
    color: '#fff',
    textDecoration: 'underline',
    cursor: 'pointer',
    transition: 'color 0.3s ease',
  },
  phoneHover: {
    color: '#a0aec0',
  },
  address: {
    color: '#fff',
  },
};

export const query = graphql`
  query portifolioSiteBlockContent {
    allBlockContent(filter: { page: { in: ["site/contact-us", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
  }
`;

export default IndexPage;
